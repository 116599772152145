import { buildApiMutationHook, buildApiQueryHook } from '../../utils/api'
import { providerAxios } from '../../utils/base.api'
import { queryClient } from '../../utils/queryClient'
import {
  AFTERMARKET_ORDERS,
  HISTORY_ORDERS,
  REPORTS_URL,
  REPORT_DETAIL_URL,
  INSPECTION_ORDERS,
  STATES_LIST,
} from '../endpoints'
import {
  AftermarketDiscountParams,
  AftermarketDiscountResponse,
  AftermarketOrderDetailParam,
  AftermarketOrderDetailResponse,
  AftermarketOrderSubmitResponse,
  AftermarketPaymentResponse,
  AftermarketReportsResponse,
  CarPostStateListResponse,
  InspectionOrderDetailParam,
  InspectionOrderDetailResponse,
  InspectionOrderDetailStatesResponse,
  OrderReportDetailParams,
  OrderReportDetailResponse,
  OrdersListParams,
  UserOrdersResponseDTO,
} from './Orders.dto'

export const useUserOrderList = buildApiQueryHook<OrdersListParams, UserOrdersResponseDTO>(
  (params) => `user-orders-${params.service_name}-${params.carId}`,
  (params) => {
    return providerAxios.get(HISTORY_ORDERS, {
      params: {
        service_name: params.service_name,
        limit: params.limit ?? '100',
        car_profile_id: params.carId,
      },
    })
  },
)

export const useGetOrderReportDetail = buildApiQueryHook<
  OrderReportDetailParams,
  OrderReportDetailResponse
>(
  (params) => `order-report-detail-${params.id}`,
  (params) => {
    return providerAxios.get(`${REPORT_DETAIL_URL}${params.id}/report-detail/`)
  },
)

export const useGetAftermarketReports = buildApiQueryHook<void, AftermarketReportsResponse>(
  () => `aftermarket-reports`,
  () => {
    return providerAxios.get(REPORTS_URL)
  },
)

export const useGetCarPostStatesList = buildApiQueryHook<void, CarPostStateListResponse>(
  () => `carpost-states-list`,
  () => {
    return providerAxios.get(STATES_LIST)
  },
)


export const useGetAftermarketOrderDetail = buildApiQueryHook<
  AftermarketOrderDetailParam,
  AftermarketOrderDetailResponse
>(
  (params) => `aftermarket-order-${params.orderId}`,
  (params) => providerAxios.get(`${AFTERMARKET_ORDERS}/${params.orderId}/`),
)

export const useGetInspectionOrderDetail = buildApiQueryHook<
  InspectionOrderDetailParam,
  InspectionOrderDetailResponse
>(
  (params) => `inspection-order-${params.orderId}`,
  (params) =>
    providerAxios.get(`${INSPECTION_ORDERS}/${params.orderId}/`, { withCredentials: false }),
)

export const useGetInspectionOrderDetailStates = buildApiQueryHook<
  InspectionOrderDetailParam,
  InspectionOrderDetailStatesResponse
>(
  (params) => `inspection-order-states-${params.orderId}`,
  (params) =>
    providerAxios.get(`${INSPECTION_ORDERS}/${params.orderId}/states/`, { withCredentials: false }),
)

export const useGetAftermarketOrderPayments = buildApiQueryHook<
  AftermarketOrderDetailParam,
  AftermarketPaymentResponse
>(
  (params) => `aftermarket-payment-${params.orderId}`,
  (params) => providerAxios.get(`${AFTERMARKET_ORDERS}/${params.orderId}/payments/`),
)

export const useSubmitAftermarketOrder = buildApiMutationHook<
  AftermarketOrderDetailParam,
  AftermarketOrderSubmitResponse
>((params) => providerAxios.post(`${AFTERMARKET_ORDERS}/${params.orderId}/submit/`))

export const useSubmitAftermarketDiscount = buildApiMutationHook<
  AftermarketDiscountParams,
  AftermarketDiscountResponse
>(
  (params) =>
    providerAxios.post(`${AFTERMARKET_ORDERS}/${params.orderId}/discount/`, {
      discount_code: params.discount_code,
    }),
  (configs) => ({
    ...configs,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(`aftermarket-payment-${args[1]?.orderId}`)
      queryClient.invalidateQueries(`aftermarket-order-${args[1]?.orderId}`)
      configs?.onSuccess?.(...args)
    },
  }),
)

export const useDeleteAftermarketDiscount = buildApiMutationHook<AftermarketDiscountParams, void>(
  (params) =>
    providerAxios.delete(
      `${AFTERMARKET_ORDERS}/${params.orderId}/discount/${params.discount_code}/`,
    ),
  (configs) => ({
    ...configs,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(`aftermarket-payment-${args[1]?.orderId}`)
      queryClient.invalidateQueries(`aftermarket-order-${args[1]?.orderId}`)
      configs?.onSuccess?.(...args)
    },
  }),
)
