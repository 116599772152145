const BASE_URL = import.meta.env.VITE_API_BASE_URL
const BOOGH_BASE_URL = import.meta.env.VITE_API_BOOGH_BASE_URL
const KARNAMEH_REST_BASE_URL = import.meta.env.VITE_KARNAMEH_REST_BASE_URL
const KARNAMEH_API_2_BASE_URL = import.meta.env.VITE_KARNAMEH_API_2_BASE_URL
const AFTERMARKET_BASE_URL = import.meta.env.VITE_AFTERMARKET_BASE_URL
const POSTSTORAGE_BASE_URL = import.meta.env.VITE_API_POST_STORAGE_URL

// SWITCH endpoints
export const SEND_OTP = `${BASE_URL}/switch/auth/otp/send/`
export const CHECK_OTP = `${BASE_URL}/switch/auth/otp/confirm/`
export const CURRENT_USER = `${BASE_URL}/switch/users/current-user/`
export const REFRESH_TOKEN = `${BASE_URL}/switch/auth/refresh/`
export const SERVICES_LIST = `${BASE_URL}/switch/services/`
export const UPDATE_CURRENT_USER = `${BASE_URL}/switch/users/current-user/`
export const UPLOAD_MEDIA = `${BASE_URL}/switch/media/`
export const LOGOUT_API = `${BASE_URL}/switch/auth/logout/`

// BOOGH endpoints
export const PUSH_TOKEN = `${BOOGH_BASE_URL}/api/push_token/`
export const INVALIDATE_PUSH_TOKEN = `${BOOGH_BASE_URL}/api/push_token`

// Karnameh rest endpoints
export const SEARCH_BRAND_MODELS = `${KARNAMEH_REST_BASE_URL}/find_cars/v1/search`
export const INSPECTION_ORDERS = `${KARNAMEH_REST_BASE_URL}/evaluation/v1/mycar/inspections`
export const CONTACT_US = `${KARNAMEH_REST_BASE_URL}/contact-us`

// KARNAMEH API2 end points
export const RELATED_CAR_POSTS = `${KARNAMEH_API_2_BASE_URL}/cars/v1/car-posts/related`

// My Car endpoints
export const CAR_PROFILE = `${BASE_URL}/car-profiles/`
export const INSPECTION_REPORT = `${BASE_URL}/inspection/`

// Estimator end points
export const PRICE_ESTIMATOR = `${CAR_PROFILE}/estimate/`
export const ESTIMATOR_BRAND_MODELS = `${BASE_URL}/priceestimator/brand-models/`

// After market end points
export const REPORT_DETAIL_URL = `${BASE_URL}/aftermarket/internal/order/`
export const REPORTS_URL = `${AFTERMARKET_BASE_URL}/internal/orders/?state=done&has_report=true`
export const AFTERMARKET_ORDERS = `${AFTERMARKET_BASE_URL}/public/orders`

export const HISTORY_SERVICES = `${BASE_URL}/services/`
export const HISTORY_ORDERS = `${BASE_URL}/orders/`

// Notifications list end points
export const NOTIFICATIONS_LIST = `${BASE_URL}/boogh/in_app_notif/`

// Price Report
export const PRICE_REPORT = `https://mycar.kaas.karnameh.com/api/priceestimator/pricing_report/user-pricing-report`
export const PRICE_REPORT_DETAILS = `https://mycar.kaas.karnameh.com/api/priceestimator/pricing_report/mycar`

// Post Storage end points
export const STATES_LIST = `${POSTSTORAGE_BASE_URL}/states/`
